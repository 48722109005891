import React, { useEffect, useState } from "react";
import { Images } from "../../Assets/Index";
import Commontable from "../../Components/Commontable";
import {
  // AllTransactions,
  // GetFundReq,
  getWithdrawreq,
} from "../../ApiServices/Apifun";
import { Button, Tooltip } from "antd";
import { dispatchtoast } from "../../Utils";
// import { APIURL } from "../../ApiServices/Axios";
import { useSelector } from "react-redux";
import { FaEye } from "react-icons/fa";

const WithdrawalRequests = () => {
  const [withdrareq, setWithdrawreq] = useState([]);
  const walletBalance = useSelector((state) => state.colorGame.walletBal);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const getData = () => {
    getWithdrawreq(`?limit=10&page=${page}`)
      .then((res) => {
        if (res.status && res.data.data.length !== 0) {
          setWithdrawreq([
            ...withdrareq,
            ...res.data.data.map((i, index) => {
              return {
                // txn_type: <p className="capitalize">Fund Requests</p>,
                Date: <p>{new Date(i.createdAt).toDateString()}</p>,
                amount: <p>{i.amount}</p>,
                BankDetail: (
                  <Tooltip
                    title={
                      <div>
                        {Object.keys(i.bank_detail).map((key, index) => (
                          <p className="text-sm">
                            <span className="capitalize">
                              {key.replace("_", " ")}:{" "}
                            </span>
                            {i.bank_detail[key]}
                          </p>
                        ))}
                      </div>
                    }
                  >
                    <FaEye className="cursor-pointer"/>
                  </Tooltip>
                ),
                Status: <p>{i.status}</p>,
              };
            }),
          ]);
        } else {
          dispatchtoast("No Data Available!", true);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  const handleLoad = () => {
    setLoading(true);
    setPage((prev) => prev + 1);
  };
  useEffect(() => {
    getData();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);
  return (
    <>
      <div className="flex justify-center mt-4 pb-20 items-center ">
        <div className="w-full">
          <img
            src={Images.Logo}
            className="w-[100px] flex justify-center items-center my-2 m-auto"
            alt="logo"
          />
          <div className="flex justify-end bg-darkPurple px-8  py-1 mt-4 rounded-lg gap-x-3 items-center">
            <div className="text-black font-semibold flex-col justify-center items-center text-xs">
              <p>Wallet</p>
              <img src={Images.Money} className="w-8 m-auto my-2" alt="money" />
            </div>
            <p className="text-black font-semibold">Rs. {walletBalance}</p>
          </div>
          <div className="mt-4">
            <Commontable
              rows={withdrareq}
              gridNum={4}
              column={["Date", "Amount", "BankDetail", "Status"]}
            />
          </div>
          <div className="mt-4 flex justify-center items-center ">
            <Button
              onClick={handleLoad}
              loading={loading}
              className="bg-darkPurple text-black hover:border-none border-none outline-none hover:outline-none"
            >
              Load More
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default WithdrawalRequests;
