import { Button, Form, Input, Modal } from "antd";
import React, { useRef, useState } from "react";
import { Images } from "../../Assets/Index";
import { useSelector } from "react-redux";
import { IoIosCloseCircle } from "react-icons/io";
import { MakeBet } from "../../ApiServices/Apifun";
import { dispatchtoast } from "../../Utils";

const BetModal = ({ isOpen, setIsOpen, data,tabs,type,apiCall=()=>{} }) => {
  const formref = useRef();
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const walletBal = useSelector((state) => state.colorGame.walletBal);
  const roomDetail = useSelector((state) => state.colorGame.roomDetail);
  const handleBet = (val) => {
    setLoading(true);
    MakeBet({ ...val, option: data.id, room: roomDetail.room_id,room_type:tabs[type]?.type })
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          setIsOpen(false);
          apiCall()
        }
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <Modal
      closeIcon={<IoIosCloseCircle />}
      open={isOpen}
      onCancel={() => setIsOpen(false)}
      footer={null}
    >
      <div>
        <div className="flex flex-col justify-center items-center">
          <div className="relative">
            <img src={Images[data.image]} className="w-1/2 m-auto" alt="selectedOption" />
          </div>
          <p className=" font-bold text-black">{data.name}</p>
          <p className="text-lg font-bold mt-1">Joining Above</p>
        </div>
        <p className="text-[16px] mt-2 font-semibold text-black">
          Available Wallet Balance:{" "}
          <span className="text-black">{walletBal}</span>
        </p>
        <div>
          <Form
            ref={formref}
            form={form}
            onFinish={(val) => handleBet(val)}
            onFinishFailed={(err) => console.log(err)}
          >
            <Form.Item
              rules={[
                {
                  required: true,
                  message: "Please enter amount",
                },
              ]}
              labelCol={{ span: 24 }}
              name="amount"
              label="Amount"
            >
              <Input
                onInput={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  e.target.value = value;
                }}
              />
            </Form.Item>
            <div className="flex justify-start gap-4 shadow-md shadow-gray-300 p-4 items-center flex-wrap">
              {[10, 50, 100, 200, 500, 1000, 2000, 5000].map((i, index) => (
                <p
                  onClick={() =>
                    formref.current.setFieldsValue({
                      amount: i,
                    })
                  }
                  className="bg-black cursor-pointer text-white px-8 py-2 rounded-lg"
                >
                  {i}
                </p>
              ))}
            </div>
            {/* <Form.Item labelCol={{ span: 24 }}>
              <Checkbox>I agree to these Terms and Conditions.</Checkbox>
            </Form.Item> */}
            <Form.Item>
              <div className="flex mt-4 justify-end items-center gap-4">
                <Button
                  loading={loading}
                  htmlType="submit"
                  className="text-white bg-black border-none hover:border-none hover:outline-none"
                >
                  Confirm
                </Button>
                <Button
                  onClick={() => setIsOpen(false)}
                  className="text-white bg-black border-none hover:border-none hover:outline-none"
                >
                  Cancel
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

export default BetModal;
