import { configureStore } from "@reduxjs/toolkit";
import colorReducer from "./B2bslice";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage/"; // Choose your preferred storage engine
const persistConfig = {
  key: "root", // Root key for the persisted data
  storage, // Storage engine to use
  // Optionally, you can specify the whitelist or blacklist of reducers to persist
  // whitelist: ['reducer1', 'reducer2'], // Only persist these reducers
  // blacklist: ['reducer3', 'reducer4'], // Do not persist these reducers
};
const persistedReducer = persistReducer(persistConfig, colorReducer);
const store = configureStore({
  reducer: {
    colorGame: persistedReducer,
  },
});
const persistor = persistStore(store);
export { store, persistor };
