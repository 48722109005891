import React, { useEffect, useState } from "react";
import { Images } from "../../Assets/Index";
import { Numbers } from "./NumberArr";
import { FaRegClock } from "react-icons/fa";
import Commontable from "../../Components/Commontable";
import {
  GetRoomDetail,
  MyBets,
  getWinningHistory,
} from "../../ApiServices/Apifun";
import { useDispatch, useSelector } from "react-redux";
import { setRoomDetail } from "../../Store/B2bslice";
import BetModal from "./BetModal";
import { convertSeconds, dispatchtoast, getColor, getLocalStorageItem } from "../../Utils";
import ResultModal from "./ResultModal";
import { Button, Spin } from "antd";
import { SocketUrl } from "../../ApiServices/Axios";
import { io } from "socket.io-client";

const Home = () => {
  const dispatch = useDispatch();
  const [timeRemaining, setTimeRemaining] = useState(0); // Time remaining in milliseconds
  const [cantbet, setCantbet] = useState(false);
  const [pageLoader, setPageLoader] = useState(false);
  const [resultr, setResult] = useState(null);
  const [myBets, setMyBets] = useState([]);
  const [winHis, setWinHis] = useState([]);
  const [socket, setSocket] = useState(null);
  const [incomingtime, setIncomingTime] = useState(0);
  const [betStatus, seBetStatus] = useState(true);
  const [type, setType] = useState(0);
  const [recordType, setRecordType] = useState(0);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  const [showResult, setShowResult] = useState({
    show: false,
    num: null,
  });
  const [openBetModal, setOpenBetModal] = useState(false);
  const [data, setData] = useState(null);
  const roomDetail = useSelector((state) => state.colorGame.roomDetail);
  const walletBalance = useSelector((state) => state.colorGame.walletBal);
  useEffect(() => {
    const socketInstance = io(SocketUrl,{
      transports:["websocket"]
    }); // Replace with your server URL

    setSocket(socketInstance);

    return () => {
      socketInstance.disconnect();
    };
    //eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (socket) {
      socket.on("roomTimmer", (data) => {
        setIncomingTime(data?.secondDetail);
        seBetStatus(data?.betPlaceStatus);
      });
    }
    return () => {
      socket?.off("roomTimmer"); // Clean up the listener
    };
  }, [socket]);
  const TypeTab = [
    {
      id: 0,
      type: "dubai_club_3_min",
    },
    {
      id: 1,
      type: "dubai_club_1_min",
    },
  ];
  const HistoryTab = [
    {
      id: 0,
      type: "My Games",
    },
    {
      id: 1,
      type: "Game History",
    },
  ];
  const GetRoom = () => {
    GetRoomDetail()
      .then((res) => {
        if (res.status) {
          dispatch(setRoomDetail(res.data));
        }
      })
      .catch((err) => console.log(err));
  };
  const GetResult = () => {
    GetRoomDetail()
      .then((res) => {
        if (res.status) {
          setResult(res.data.winner_option);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleLoad = () => {
    setPage((prev) => prev + 1);
  };
  const handlePrev = () => {
    setPage((prev) => prev - 1);
  };
  const GetMyBets = () => {
    setPageLoader(true);
    MyBets(
      `?fields=_id,bet_amount,createdAt,choose_option_id,platform_service_amt,option.id,room,option.color_values&limit=10&page=${page}`
    )
      .then((res) => {
        if (res.status) {
          setMyBets(
            res.data?.data?.map((i, index) => {
              return {
                id: <p>{i.room.room_id}</p>,
                price: <p>{i.bet_amount}</p>,
                number: <p>{getColor(i.option.id)?.name}</p>,
                // bigsmall:
                //   i.option.id >= 4 && i.option.id <= 8 ? (
                //     <p>Small</p>
                //   ) : (
                //     <p>Big</p>
                //   ),
                result:
                  i.option.id !== 14 && i.option.id !== 15 ? (
                    <img
                      alt="result"
                      className="w-12 flex justify-center items-center"
                      src={Images[getColor(i.option.id)?.color]}
                    />
                  ) : (
                    <p>N/A</p>
                  ),
              };
            })
          );
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setPageLoader(false));
  };
  const GetWinninghistory = () => {
    setPageLoader(true);
    getWinningHistory(`?limit=10&page=${page}`)
      .then((res) => {
        if (res.status) {
          setWinHis(
            res.gameHistoryDetails?.map((i, index) => {
              return {
                id: <p>{i.room_id}</p>,
                roomType: (
                  <p className="text-xs capitalize">
                    {i.room_type?.replace(/_/g, " ")}
                  </p>
                ),
                number: <p>{getColor(i.winner_option)?.name}</p>,
                // bigsmall:
                //   i.winner_option >= 4 && i.winner_option <= 8 ? (
                //     <p>Small</p>
                //   ) : (
                //     <p>Big</p>
                //   ),
                result:
                  i.winner_option !== 14 && i.winner_option !== 15 ? (
                    <img
                      alt="result"
                      className="w-12 flex justify-center items-center"
                      src={Images[getColor(i.winner_option)?.color]}
                    />
                  ) : (
                    <p>N/A</p>
                  ),
              };
            })
          );
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setPageLoader(false));
  };
  useEffect(() => {
    setCantbet(false);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);
  useEffect(() => {
    setPage(1);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, recordType]);
  useEffect(() => {
    if (recordType === 0) {
      GetMyBets();
    } else {
      GetWinninghistory();
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, type, recordType]);
  useEffect(() => {
    GetRoom();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);
  useEffect(() => {
    if (resultr && showResult.show) {
      setShowResult({
        show: true,
        num: resultr,
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resultr, showResult]);

  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     const currentTime = navigator.geolocation.getCurrentPosition((time) => {
  //       return time.timestamp;
  //     });
  //     // const currentTime = Date.now();

  //     const remaining = roomDetail.room_close_time - currentTime;
  //     if (roomDetail.room_bet_close_time <  remaining) {
  //       GetResult();
  //       setCantbet(true);
  //     }
  //     if (
  //       currentTime >= roomDetail.room_start_time &&
  //       currentTime <= roomDetail.room_close_time
  //     ) {
  //       setTimeRemaining(remaining);
  //     } else {
  //       setShowResult({
  //         show: true,
  //         num: null,
  //       });
  //       // setTimeout(() => {
  //       //   window.location.reload();
  //       // }, 2000);
  //       setCantbet(false);
  //       clearInterval(intervalId);
  //     }
  //   }, 1000);

  //   return () => {
  //     clearInterval(intervalId);
  //     setShowResult({
  //       show: false,
  //       num: null,
  //     }); // Clean up the interval on component unmount
  //     setResult(null);
  //   };
  //   //eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [roomDetail, type]);
  // Assuming you have the room start time and room close time in milliseconds

  useEffect(() => {
    let timer
    if (!betStatus) {
      GetResult();
      setCantbet(true);
    }
    if (Number(incomingtime) > 1) {
      setTimeRemaining(incomingtime);
    } else {
     setShowResult({
        show: true,
        num: null,
      });
      timer= setTimeout(() => {
        window.location.reload();
      }, 5000);
      setCantbet(false);
    }
    return () => {
      setShowResult({
        show: false,
        num: null,
      }); // Clean up the interval on component unmount
      setResult(null);
      clearTimeout(timer)
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingtime, betStatus]);
  const formatTime = (milliseconds) => {
    const seconds = Math.floor(milliseconds / 1000) % 60;
    const minutes = Math.floor(milliseconds / (1000 * 60)) % 60;

    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };
  return (
    <>
      <div className="flex justify-center mt-4 pb-20 items-center ">
        <div className="w-full">
          <img
            src={Images.Logo}
            className="w-[100px] flex justify-center items-center my-2 m-auto"
            alt="logo"
          />
          <div className="flex justify-end bg-darkPurple px-8  py-2 rounded-lg gap-x-3 items-center">
            <div className="text-black font-semibold flex-col justify-center items-center text-xs">
              <p>Wallet</p>
              <img src={Images.Money} className="w-8 m-auto my-2" alt="money" />
            </div>
            <p className="text-black font-semibold">Rs. {walletBalance}</p>
          </div>
          {/* <div className="mt-8  bg-black rounded-lg grid grid-cols-2 place-items-start md:place-items-center gap-y-4">
            {TypeTab.map((i, index) => {
              return (
                <div key={index} className={`w-full   text-center`}>
                  <p
                    onClick={() => {
                      setType(index);
                    }}
                    className={`cursor-pointer  capitalize transition-all ${
                      index === type ? "font-bold" : ""
                    }  ${
                      index === type
                        ? "bg-darkPurple rounded-lg tab-active text-black  "
                        : " text-black font-semibold"
                    }  py-2 `}
                  >
                    {i.type.replace(/_/g, " ")}
                  </p>
                </div>
              );
            })}
          </div> */}
          <div className="flex justify-between  px-8 mt-2 py-2 rounded-lg gap-x-3 items-center">
            <div className=" font-semibold flex justify-center items-center text-xs">
              <img
                src={Images.trophy}
                className="w-8 m-auto my-2"
                alt="money"
              />
              <p className="text-black font-semibold text-[18px]">
                Room ID:{" "}
                <span className="text-black">{roomDetail.room_id}</span>
              </p>
            </div>
            <div className="text-center">
              <p className="text-green-500 font-mono font-bold text-3xl">
                {convertSeconds(timeRemaining)}
              </p>
              <p className="text-purple font-semibold">Time Left</p>
            </div>
          </div>
          <div className="flex  justify-around items-center">
            <div
              onClick={() => {
                if (cantbet) {
                  dispatchtoast("Bet Time is Closed", true);
                } else {
                  setData({
                    id: 1,
                    name: "Join Green",
                    image: "greenwheel",
                  });
                  setOpenBetModal(true);
                }
              }}
              className="relative cursor-pointer"
            >
              <img
                style={{ filter: "drop-shadow(2px 10px 7px #000000)" }}
                src={Images.greenwheel}
                alt="green"
                className="w-[120px]"
              />
              <p className="absolute text-sm font-bold text-green-700 top-10 text-center left-10 flex justify-center items-center">
                Join <br />
                Green
              </p>
            </div>
            <div
              onClick={() => {
                if (cantbet) {
                  dispatchtoast("Bet Time is Closed", true);
                } else {
                  setData({
                    id: 2,
                    name: "Join Red",
                    image: "redwheel",
                  });
                  setOpenBetModal(true);
                }
              }}
              className="relative cursor-pointer"
            >
              <img
                style={{ filter: "drop-shadow(2px 10px 7px #000000)" }}
                src={Images.redwheel}
                alt="red"
                className="w-[120px]"
              />
              <p className="absolute text-sm font-semibold text-red-700 top-10 text-center left-12 flex justify-center items-center">
                Join <br />
                Red
              </p>
            </div>{" "}
            <div
              onClick={() => {
                if (cantbet) {
                  dispatchtoast("Bet Time is Closed", true);
                } else {
                  setData({
                    id: 3,
                    name: "Join Violet",
                    image: "violetwheel",
                  });
                  setOpenBetModal(true);
                }
              }}
              className="relative cursor-pointer"
            >
              <img
                style={{ filter: "drop-shadow(2px 10px 7px #000000)" }}
                src={Images.violetwheel}
                alt="violet"
                className="w-[120px]"
              />
              <p className="absolute text-sm font-semibold text-black top-10 text-center left-10 flex justify-center items-center">
                Join <br />
                Violet
              </p>
            </div>
          </div>
          <div className="grid grid-cols-5  mt-12 gap-y-4 place-items-center">
            {Numbers.map((i, index) => (
              <div
                onClick={() => {
                  if (cantbet) {
                    dispatchtoast("Bet Time is Closed", true);
                  } else {
                    setData({
                      id: i.num + 4,
                      name: i.num,
                      image: i.imageName,
                    });
                    setOpenBetModal(true);
                  }
                }}
                className="relative cursor-pointer"
                key={index}
              >
                <img alt="numbers" src={i.image} className="w-20" />
                <p className="absolute top-[6px] text-lg left-8 font-bold text-black">
                  {i.num}
                </p>
              </div>
            ))}
          </div>
          {/* <div className="flex justify-center items-center gap-x-4 mt-5">
            <p
              onClick={() => {
                if (cantbet) {
                  dispatchtoast("Bet Time is Closed", true);
                } else {
                  setData({
                    id: 15,
                    name: "Join Big",
                    image: "big",
                  });
                  setOpenBetModal(true);
                }
              }}
              className="bg-[#FFA82E] w-full text-center text-lg text-black rounded-lg font-semibold cursor-pointer px-6 py-1"
            >
              Big
            </p>
            <p
              onClick={() => {
                if (cantbet) {
                  dispatchtoast("Bet Time is Closed", true);
                } else {
                  setData({
                    id: 14,
                    name: "Join Small",
                    image: "small",
                  });
                  setOpenBetModal(true);
                }
              }}
              className="bg-[#6DA7F4] w-full text-center text-lg text-black rounded-lg font-semibold cursor-pointer px-6 py-1"
            >
              Small
            </p>
          </div> */}

          <div className="mt-8">
            <div className="flex text-black justify-center items-center gap-x-3">
              <FaRegClock size={30} />
              <p className="font-bold text-2xl">Recent Records</p>
            </div>
          </div>
          <div className="mt-8  bg-white rounded-lg grid grid-cols-2 place-items-start md:place-items-center gap-y-4">
            {HistoryTab.map((i, index) => {
              return (
                <div key={index} className="w-full text-center">
                  <p
                    onClick={() => {
                      setRecordType(index);
                    }}
                    className={`cursor-pointer capitalize transition-all ${
                      index === recordType ? "font-bold" : ""
                    }  ${
                      index === recordType
                        ? "bg-darkPurple rounded-lg tab-active text-black  "
                        : " text-black font-semibold"
                    }  py-2 `}
                  >
                    {i.type.replace(/_/g, " ")}
                  </p>
                </div>
              );
            })}
          </div>
          <Spin spinning={pageLoader}>
            {recordType === 0 ? (
              <div className="mt-4">
                <Commontable
                  rows={myBets}
                  gridNum={4}
                  column={["Period", "Amount", "Number", "Color"]}
                />
              </div>
            ) : (
              <div className="mt-4">
                <Commontable
                  rows={winHis}
                  gridNum={4}
                  column={["Period", "Room Type", "Number", "Color"]}
                />
              </div>
            )}
          </Spin>
          <div className="mt-4 flex justify-center gap-3 items-center ">
            {page > 1 && (
              <Button
                onClick={handlePrev}
                loading={loading}
                className="bg-darkPurple text-black hover:border-none border-none outline-none hover:outline-none"
              >
                Previous
              </Button>
            )}
            <Button
              onClick={handleLoad}
              loading={loading}
              className="bg-darkPurple text-black hover:border-none border-none outline-none hover:outline-none"
            >
              Next
            </Button>
          </div>
        </div>
      </div>
      {openBetModal && (
        <BetModal
          isOpen={openBetModal}
          tabs={TypeTab}
          apiCall={GetMyBets}
          type={type}
          setIsOpen={setOpenBetModal}
          data={data}
        />
      )}
      {showResult.show && (
        <ResultModal
          open={showResult.show}
          setIsOpen={setShowResult}
          num={showResult.num}
        />
      )}
    </>
  );
};

export default Home;
