
import Login from "../Screens/Auth/Login";
import Otp from "../Screens/Auth/Otp";
import Registration from "../Screens/Auth/Registration";
import ResetPasswordPage from "../Screens/Auth/ResetPassword";
import VerifyOtpScreen from "../Screens/Auth/Verifyotp";
import PublicAuth from "./PublicAuth";

export const PublicRoutes = () => {
  return [
    {
      element: <PublicAuth />,
      children: [
        {
          path: "/register",
          element: <Registration />,
        },
        {
          path: "/reset-password",
          element: <ResetPasswordPage />,
        },
        {
          path: "/",
          element: <Login />,
        },
        {
          path: "/otp",
          element: <Otp />,
        },
        {
          path: "/verifyotp",
          element: <VerifyOtpScreen />,
        },
      ],
    },
  ];
};
