import { createSlice } from "@reduxjs/toolkit";
// import { getLocalStorageItem } from "../Utils";

const initialState = {
  walletBal:"",
  roomDetail: {},
};

export const colorSlice = createSlice({
  name: "colorGame",
  initialState,
  reducers: {
    setWalletBalance: (state, action) => {
      state.walletBal = action.payload;
    },
    setRoomDetail: (state, action) => {
      state.roomDetail = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {setRoomDetail,setWalletBalance} = colorSlice.actions;

export default colorSlice.reducer;
