import { Button, Checkbox, Form, Input } from "antd";
import React, { useRef, useState } from "react";
import { Images } from "../../Assets/Index";
import {  ResendOtp, userRegister } from "../../ApiServices/Apifun";
import { useNavigate } from "react-router-dom";
import { dispatchtoast } from "../../Utils";

const Registration = () => {
  const navigation = useNavigate();
  const formref=useRef()
  const[form]=Form.useForm()
  const [loading, setloading] = useState(false);
  const [tandc, setTandc] = useState(0);
  const[token,setToken]=useState(null)
  const handleSubmit = (val) => {
    setloading(true);
    userRegister({ ...val, tandc })
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          // navigation("/")
          setToken(res.verificationToken)
          navigation("/verifyotp", {
            state: { token: res.verificationToken, mobile: val.mobile },
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setloading(false));
  };
  const handleResend = () => {
    const payload={
      mobile:form.getFieldValue("mobile"),
      verificationToken:token
    }
    ResendOtp(payload)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };
  return (
    <div className="flex  h-screen justify-center items-center">
      <div className=" grid grid-cols-1 md:grid-cols-2 place-items-center relative shadow-lg mx-8 shadow-gray-500 rounded-lg   px-10  py-14">
        <div className="flex justify-center items-center flex-col">
          <img src={Images.Logo} className="w-[150px]" alt="logo" />
          <p className="mt-2 text-black font-bold">Color Game Prediction</p>
        </div>
        <div>
          <Form ref={formref} form={form}
            onFinishFailed={(err) => console.log(err)}
            onFinish={(val) => handleSubmit(val)}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 place-items-center">
              <Form.Item
                label={<p className="text-black">Mobile</p>}
                name={"mobile"}
                className="m-0 w-full"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter mobile number",
                  },
                ]}
              >
                <Input
                  className="rounded-none bg-white"
                  placeholder="Enter Number"
                />
              </Form.Item>
              <Form.Item
                label={<p className="text-black">Email</p>}
                name={"email"}
                className="m-0 w-full"
                labelCol={{ span: 24 }}
              >
                <Input
                  className="rounded-none bg-white"
                  placeholder="Enter Email"
                />
              </Form.Item>
              <Form.Item
                label={<p className="text-black">Password</p>}
                name={"password"}
                className="m-0 w-full"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter password",
                  },
                ]}
              >
                <Input
                  className="rounded-none bg-white"
                  placeholder="Enter Password"
                />
              </Form.Item>
              <Form.Item
                label={<p className="text-black">Confirm Password</p>}
                name={"confirm_password"}
                className="m-0 w-full"
                labelCol={{ span: 24 }}
                rules={[
                  {
                    required: true,
                    message: "Please enter confirm password",
                  },
                ]}
              >
                <Input
                  className="rounded-none bg-white"
                  placeholder="Enter Confirm Password"
                />
              </Form.Item>
            </div>
            <div className="mt-1 text-black flex justify-between items-center font-semibold">
              <Form.Item name="tandc">
                <Checkbox
                  onChange={(e) => setTandc(e.target.checked ? 1 : 0)}
                  className="mt-2 text-black"
                >
                  Agree T&C*
                </Checkbox>
              </Form.Item>
               <p onClick={handleResend} className="cursor-pointer">
                Resend Otp
              </p>
            </div>
            <Form.Item
              className="w-full"
              labelCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Please enter mobile number",
                },
              ]}
            >
              <Button
                loading={loading}
                htmlType="submit"
                className="w-full mt-2 font-semibold border-none outline-none hover:border-none hover:outline-none bg-green-600 text-black"
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="w-24 h-24 animate-pulse bg-green-600 absolute top-0 right-0 rounded-bl-full"></div>
        <div className="w-24 h-24 bg-red-600 animate-pulse absolute bottom-0 left-0 rounded-tr-full"></div>
      </div>
    </div>
  );
};

export default Registration;
