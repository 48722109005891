import React from "react";
import { Images } from "../../Assets/Index";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Wallet = () => {
  const walletBalance = useSelector((state) => state.colorGame.walletBal);

  return (
    <>
      <div className="flex justify-center mt-4 pb-20 items-center ">
        <div className="w-full">
          <img
            src={Images.Logo}
            className="w-[100px] flex justify-center items-center my-2 m-auto"
            alt="logo"
          />
          <div className="flex justify-end bg-darkPurple px-8  py-1 mt-4 rounded-lg gap-x-3 items-center">
            <div className="text-black font-semibold flex-col justify-center items-center text-xs">
              <p>Wallet</p>
              <img src={Images.Money} className="w-8 m-auto my-2" alt="money" />
            </div>
            <p className="text-black font-semibold">Rs. {walletBalance}</p>
          </div>
          <div className="grid grid-cols-2 md:grid-cols-3 gap-y-3 mt-2 place-items-center">
            <NavLink to={"/wallet-transaction"}>
              <div className="cursor-pointer transition-all text-black hover:bg-darkPurple hover:text-black hover:scale-100 font-bold shadow-md shadow-gray-500 rounded-full w-36 h-36 flex justify-center items-center">
                <p className="text-center text-sm">
                  All Transaction <br />
                  History
                </p>
              </div>
            </NavLink>
           {/* <NavLink to={"/bet-history"}>
           <div className="cursor-pointer transition-all hover:bg-darkPurple hover:text-black hover:scale-100 font-bold shadow-md shadow-gray-500 rounded-full w-36 h-36 flex justify-center items-center">
              <p className="text-center text-sm">
                Bet Amount <br />
                History
              </p>
            </div>{" "}
           </NavLink> */}
            <NavLink to={"/fund-requests"}>
              <div className="cursor-pointer transition-all text-black hover:bg-darkPurple hover:text-black hover:scale-100 font-bold shadow-md shadow-gray-500 rounded-full w-36 h-36 flex justify-center items-center">
                <p className="text-center text-sm">
                  Fund Request <br />
                  History
                </p>
              </div>{" "}
            </NavLink>
            {/* <div className="cursor-pointer transition-all hover:bg-darkPurple hover:text-black hover:scale-100 font-bold shadow-md shadow-gray-500 rounded-full w-36 h-36 flex justify-center items-center">
              <p className="text-center text-sm">
                Referral Betting Bonus <br />
                History
              </p>
            </div>{" "} */}
            {/* <NavLink to={"/winning-history"}>
              <div className="cursor-pointer transition-all hover:bg-darkPurple hover:text-black hover:scale-100 font-bold shadow-md shadow-gray-500 rounded-full w-36 h-36 flex justify-center items-center">
                <p className="text-center text-sm">
                  Winning Amount <br />
                  History
                </p>
              </div>{" "}
            </NavLink> */}
            <NavLink to={"/withdrawal-requests"}>
              <div className="cursor-pointer transition-all text-black hover:bg-darkPurple hover:text-black hover:scale-100 font-bold shadow-md shadow-gray-500 rounded-full w-36 h-36 flex justify-center items-center">
                <p className="text-center text-sm">
                  Withdrawal Request <br />
                  History
                </p>
              </div>
            </NavLink>
          </div>
          <div className="flex mt-4 justify-between items-center">
            <NavLink to={"/add-fund"}>
              <div className="bg-darkPurple font-semibold text-black px-8 py-2 rounded-md">
                <p>Add Fund +</p>
              </div>
            </NavLink>
            <NavLink to={"/add-Withdrawal"}>
              <div className="bg-darkPurple font-semibold text-black px-8 py-2 rounded-md">
                <p>New Withdrawal Request +</p>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
