import { Images } from "../../Assets/Index";

export const Numbers = [
  {
    num: 0,
    image: Images.RedViol,
    imageName: "RedViol",
  },
  {
    num: 1,
    image: Images.Green,
    imageName: "Green",
  },
  {
    num: 2,
    image: Images.Red,
    imageName: "Red",
  },
  {
    num: 3,
    image: Images.Green,
    imageName: "Green",
  },
  {
    num: 4,
    image: Images.Red,
    imageName: "Red",
  },
  {
    num: 5,
    image: Images.greenviol,
    imageName: "greenviol",
  },
  {
    num: 6,
    image: Images.Red,
    imageName: "Red",
  },
  {
    num: 7,
    image: Images.Green,
    imageName: "Green",
  },
  {
    num: 8,
    image: Images.Red,
    imageName: "Red",
  },
  {
    num: 9,
    image: Images.Green,
    imageName: "Green",
  },
];
