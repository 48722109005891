import Logo from "./Logo.png"
import Money from "./Group 23.png"
import trophy from "./reshot-icon-prize-cup-7ZQ95TMUWF 1.png"
import greenwheel from "./Green.png"
import redwheel from "./Red.png"
import violetwheel from "./Volet.png"
import RedViol from "./RP.png"
import Green from "./Layer 5.png"
import Red from "./R.png"
import greenviol from "./GP.png"
import NoImage from "../Assets/No-Image-Placeholder.jpg";
import big from "../Assets/big.png";
import small from "../Assets/small.png";


export const Images={
Logo,Money,trophy,greenwheel,redwheel,violetwheel,RedViol,Green,Red,greenviol,NoImage,big,small
}