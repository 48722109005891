import React from "react";
import { Navigate, Outlet} from "react-router-dom";

import { getLocalStorageItem } from "../Utils";
import Layout from "../Layout/Layout";

function ProtectedAuth() {
  const isAuthenticated = () => {
    const user = getLocalStorageItem("isLoggedIn");
    return user;
  };

  return isAuthenticated() ? (
    <>
      {" "}
      <Layout>
        <Outlet />
      </Layout>
    </>
  ) : (
    <>
      <Navigate to="/" replace />
    </>
  );
}
export default ProtectedAuth;
