import AddFund from "../Screens/AddFund/AddFund";
import Addwithdrawal from "../Screens/AddWithdraw/AddWithdraw";
import Home from "../Screens/Home/Home";
import Profile from "../Screens/Profile/Profile";
import Support from "../Screens/Support/Support";
import BetHistory from "../Screens/Wallet/BetHistory";
import FundRequests from "../Screens/Wallet/FundRequests";
import Wallet from "../Screens/Wallet/Wallet";
import WalletTransaction from "../Screens/Wallet/WalletTransaction";
import WinningHistory from "../Screens/Wallet/WinningHistory";
import WithdrawalRequests from "../Screens/Wallet/WithdrawalRequests";
import ProtectedAuth from "./PrivateAuth";

export const PrivateAuth = () => {
  return [
    {
      element: <ProtectedAuth />,
      children: [
        {
          path: "/Dashboard",
          element: <Home />,
        },
        {
          path: "/wallet",
          element: <Wallet />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
        {
          path: "/support",
          element: <Support />,
        },
        {
          path: "/add-fund",
          element: <AddFund />,
        },
        {
          path: "/add-Withdrawal",
          element: <Addwithdrawal />,
        },
        {
          path: "/wallet-transaction",
          element: <WalletTransaction />,
        },
        {
          path: "/fund-requests",
          element: <FundRequests />,
        },
        {
          path: "/withdrawal-requests",
          element: <WithdrawalRequests />,
        },
        {
          path: "/winning-history",
          element: <WinningHistory />,
        },
        {
          path: "/bet-history",
          element: <BetHistory />,
        },
        
        // ERROR ROUTES ENDED
      ],
    },
  ];
};
