import React, { useEffect } from "react";
import { FaHome } from "react-icons/fa";
import { CiWallet } from "react-icons/ci";
import { BiSupport } from "react-icons/bi";
import { CgProfile } from "react-icons/cg";
import { IoIosLogOut } from "react-icons/io";
import { NavLink, useNavigate } from "react-router-dom";
import { walletBal } from "../ApiServices/Apifun";
import { useDispatch } from "react-redux";
import { setWalletBalance } from "../Store/B2bslice";

const Layout = ({ children }) => {
  const navigate=useNavigate()
  const dispatch = useDispatch();
  const getWallletBal = () => {
    walletBal()
      .then((res) => {
        if (res.status) {
          dispatch(setWalletBalance(res.data.balance));
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getWallletBal()
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {/* <img src={Images.Logo} className="w-[100px] flex justify-center items-center mt-2 m-auto" alt="logo" /> */}
      <div className="w-[600px] px-6 md:px-0 m-auto">{children}</div>
      <div className="flex w-[600px] m-auto bg-darkPurple fixed bottom-0 left-0 right-0  justify-around py-2 rounded-tl-md rounded-tr-md items-center">
        <NavLink to={"/dashboard"}>
          <div className="flex cursor-pointer justify-center items-center flex-col">
            <FaHome color="white" size={20} />
            <p className="text-white font-semibold">Home</p>
          </div>
        </NavLink>
        <NavLink to={"/wallet"}>
          <div className="flex cursor-pointer justify-center items-center flex-col">
            <CiWallet
              color="white"
              size={20}
              className="flex cursor-pointer justify-center items-center"
            />
            <p className="text-white font-semibold">Wallet</p>
          </div>
        </NavLink>
        <NavLink to={"/support"}>
          <div className="flex cursor-pointer justify-center items-center flex-col">
            <BiSupport color="white" size={20} />
            <p className="text-white font-semibold">Support</p>
          </div>
        </NavLink>
        <NavLink to={"/profile"}>
          <div className="flex cursor-pointer justify-center items-center flex-col">
            <CgProfile color="white" size={20} />
            <p className="text-white font-semibold">Profile</p>
          </div>
        </NavLink>
        <div onClick={()=>{
          localStorage.clear()
          navigate("/")
        }} className="flex cursor-pointer justify-center items-center flex-col">
          <IoIosLogOut color="white" size={20} />
          <p className="text-white font-semibold">Logout</p>
        </div>
      </div>
    </>
  );
};

export default Layout;
