export const Authendpoint = {
  Register: "auth/register",
  Login: "auth/login-mobile",
  OtpVerify: "auth/verify-otp",
  forgotPassword:"auth/forgot-password",
  forgotPasswordOtpVerify:"auth/forget-password-otp-verify",
  resetPassword:"auth/reset-password",
  resendOtp: "auth/resend-otp",
};
export const Globalendpoints = {
  profile:"user/profile",
  updateProfile:"user/update-profile",
  walletBalance: "user/wallet-balance",
  getRoom: "color/room",
  betting:"color/betting",
  myBets:"color/my-games",
  allTransaction:"user/wallet-transactions",
  AddFundReq:"user/add-fund-request",
  fundReq:"user/fund-requests",
  AddWithdraReq:"user/add-withdraw-request",
  withdrawReq:"user/withdraw-requests",
  winningHistory:"color/fetch-game-history",
  betHistory:"color/bet-history",
  bankDetail:"user/setting"
};
